import { isEmpty } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { consumeActionReturn } from '../api/firebase/actionReturn';
import {
  applyActionCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithCustomToken,
  signInWithEmail,
  signOut,
  signUp,
  verifyActionCode,
} from '../api/firebase/auth';
import {
  getCustomToken,
  getIdToken,
  sendVerificationEmail,
} from '../api/firebase/currentUser';
import {
  pruneSessions,
  stopAllSessions,
  stopSession,
} from '../api/firebase/session';
import { saveSignupData } from '../api/firebase/signupData';
import useCombinedAuthState from './auth/useCombinedAuthState';
import { RETURN_SIGNUP_KEY, useReturnValue } from './returnValue';
import { logWarning } from './rollbar';

const makeError = ({ errorCode }) => {
  const error = new Error(errorCode);
  error.errorCode = errorCode;
  return error;
};

/**
 * @typedef {import('firebase/auth').User} User
 */
const defaults = {
  /** @type {boolean} If the authentication initialization process is in progress. */
  isLoading: false,
  /** @type {boolean} If the authentication initialization process failed. */
  isFailed: false,
  /** @type {boolean} If the authentication initialization process completed. // once ready, this should never revert to an unready state */
  isReady: false,
  /** @type {boolean} If the auth is ready but user data is still being fetched. */
  isUserLoading: false,
  /** @type {boolean} If the authentication process found a valid user and session. */
  isAuthenticated: false,
  /** @type {User | undefined} the user object, if it exists*/
  authUser: undefined,
  /** @type {Object | undefined} the user session object, if it exists */
  authSession: undefined,
  /** @type {Error | undefined} capured auth error, if it exists */
  authError: undefined,
  /**
   * ### Async / Promise
   * Sign in an existing user in firebase and return credential information.
   * Tries to prune outdated sessions after sign in.
   * Accepts additional sign in data that will be saved into the session after sign in.
   * @param {string} email
   * @param {string} password
   * @param {Object?} optData Can provide optional data to be stored into the user session
   * @returns {Promise<UserPayload | Error | undefined>}
   * If Error, there was an expected error with the input
   * If undefined, an unrecoverable error occurred.
   * @throws {Error} if email or password is not provided
   */
  signInWithEmailAndPassword: async (email, password, _optData = {}) => {},
  /**
   * ### Async / Promise
   * Sign in an existing user in firebase and return credential information.
   * Tries to prune outdated sessions after sign in.
   * * @param {string} _token
   * * @param {Object?} _optData Can provide optional data to be stored into the user session
   * @returns {Promise<UserPayload | Error | undefined>}
   */
  signInWithCustomToken: async (_token, _optData) => {},
  /**
   * ### Async / Promise
   * Sign up a new user in firebase and return credential information.
   * Optionally accepts additional data that can be added to signup information.
   * The signup data (without the password) will be saved to the user session created
   * after sign in. The data will also be saved to the return data channel. It will also
   * be saved to a session independent document for other apps to use.
   * Profile information will be updated.
   * The user will also be signed into SSO after sign up completion.
   * This will not create any app-specific onboarding documents.
   * The app will also send the first verification email and save the return data so any device
   * that opens the email may use it.
   * Calls through to shared signup code
   * @param {UserSignupData} userData Data provided by the user during signup, including password
   * @param {Object?} optData Can provide optional data to be stored
   * @param {boolean?} hasBranding Can provide optional about branding information
   * @returns {Promise<UserPayload | Error | undefined>}
   * If Error, there was an expected error with the input
   * If undefined, an unrecoverable error occurred.
   * @throws {Error} if userData is not provided
   */
  signUpNewUser: async (userData, _optData = {}, _hasBranding = false) => {},
  /**
   * ### Async / Promise
   * Sign up an invited user in firebase and return credential information.
   * The user will also be signed into SSO after sign up completion.
   * Profile information will be updated.
   * This will not create any app-specific onboarding documents.
   * Calls through to shared signup code
   * @param {UserSignupData} userData Data provided by the user during signup, including password
   * @param {Object?} optData Can provide optional data to be stored
   * @returns {Promise<UserPayload | Error | undefined>}
   * If Error, there was an expected error with the input
   * If undefined, an unrecoverable error occurred.
   * @throws {Error} if userData is not provided
   */
  signUpInvitedUser: async (userData, _optData = {}) => {},
  /**
   * ### Async / Promise
   * Attempts to stop the current session.
   * If successful, signs out the user.
   * If successful, returns true;
   * @returns {Promise<true | undefined>}
   * If undefined, something went wrong.
   */
  signOut: async () => {},
  /**
   * ### Async / Promise
   * Attempts to stop all user sessions.
   * If successful, signs out the user.
   * If successful, returns true;
   * @returns {Promise<true | undefined>}
   * If undefined, something went wrong.
   */
  fullSignOut: async () => {},
  /**
   * ### Async / Promise
   * Given a valid user email, tries to begin the password reset process by sending an email to the user.
   * Will persist the returnData so the email can be fulfilled on any device.
   * If successful, returns the saved data
   * @param {string} email
   * @returns {Promise<Object | Error | undefined>}
   * If Error, there was an expected error with the input
   * If undefined, an unrecoverable error occurred.
   * @throws {Error} if email is not provided
   */
  sendForgotPasswordEmail: async (_email) => {},
  /**
   * ### Async / Promise
   * If there is an auth user, tries to send a verification email.
   * Will persist the returnData so the email can be fulfilled on any device.
   * If successful, returns the saved data
   * @returns {Promise<Object | Error | undefined>}
   * If Error, there was an expected error with the input
   * If undefined, something went wrong.
   */
  sendVerificationEmail: async () => {},
  /**
   * ### Async / Promise
   * Validates an action code before usage. Use this to save the user time.
   * @param {string} actionCode
   * @returns {Promise<boolean | Error>} Whether the action code is still usable
   * If Error, there was an expected error with the input
   */
  verifyActionCode: async (_actionCode) => false,
  /**
   * ### Async / Promise
   * Attempts to consume a one-time verification action code
   * @param {string} actionCode
   * @returns {Promise<boolean | Error>} Whether the action code was successfully applied
   * If Error, there was an expected error with the input
   */
  applyActionCode: async (_actionCode) => false,
  /**
   * ### Async / Promise
   * Resets password for the user attached to the action code
   * @param {string} actionCode
   * @param {string} password
   * @returns {Promise<boolean | Error>} Whether the operation succeeded
   */
  confirmPasswordReset: async (_actionCode, _password) => false,
  /**
   * ### Async / Promise
   * Gets a custom token that can be used to sign into another firebase instance.
   * This will not contain much identifying information in the token.
   * This is useful for most apps, especially ones that rely on firebase or other modern
   * authentication systems. (ex: CCMS-web and -mobile, lego-web)
   * @param {boolean} forceRefresh
   * @returns {Promise<{ jwt: string } | undefined>}
   * if undefined, something went wrong.
   */
  getCustomToken: async () => {},
  /**
   * ### Async / Promise
   * Gets the fully-hydrated id token. This will contain richer identifying information,
   * but will not be able to re-authenticate in another firebase client.
   * This is useful for legacy apps with their own authentication systems, where the app
   * just needs a way to associate a verified user identity. (ex: lego og mobile app);
   * Users the logged in user and will return undefined if that is not set.
   * @param {boolean} forceRefresh
   * @returns {Promise<{ jwt: string } | undefined>}
   * if undefined, something went wrong.
   */
  getIdToken: async (_forceRefresh) => {},
  /** @type {ACTION_TYPES} */
  /**
   * ### Async / Promise
   * gets the saved action return state from firestore,
   * consumming/deleting it in the process
   * @param {string} userId firebase id
   * @param {string} actionType type of action
   * @returns {Promise<DocumentData | undefined>}
   * if undefined, either the document was not found or an error occurred.
   * otherwise, returns the document data as json object
   * will not delete if an error occurs
   */
  consumeActionReturn: async (_userId, _actionType) => {},
};
const AuthStateContext = createContext(defaults);

const AuthStateProvider = ({ children }) => {
  const {
    isLoading,
    isFailed,
    isReady,
    isUserLoading,
    isAuthenticated,
    authUser,
    authSession,
    authError,
    sessionNeedsSignout,
    setTransitionState,
    cleanupSignout,
  } = useCombinedAuthState();
  const { returnValue, extendReturnValue, getReturnState } = useReturnValue();
  const [signingOut, setSigningOut] = useState(false);

  const doSignOut = useCallback(async () => {
    let result;
    setSigningOut(true);
    try {
      if (authSession) {
        result = !!(await stopSession(
          authSession?.userId,
          authSession?.sessionId
        ));
      }
    } catch (err) {
      logWarning(`error stopping session: ${err.message}`, { err });
    } finally {
      cleanupSignout();
    }
    try {
      result = !!(await signOut());
    } catch (err) {
      logWarning(`error signing out: ${err.message}`, { err });
    }

    setSigningOut(false);
    return result; // if undefined, failed
  }, [authSession, cleanupSignout]);

  useEffect(() => {
    if (sessionNeedsSignout && !signingOut) {
      doSignOut();
    }
  }, [doSignOut, sessionNeedsSignout, signingOut]);

  const sharedSignUp = async (userData, optData = {}, sendVerify = true) => {
    if (isEmpty(userData)) {
      throw new Error('userData is required');
    }
    const { password: _password, ...signupData } = userData;
    const returnState = getReturnState(returnValue);
    const returnData = {
      // order matters here.
      // signupData takes priority
      [RETURN_SIGNUP_KEY]: {
        ...optData,
        ...returnState,
        ...signupData,
      },
      ...optData, // set twice, once to keep it around for later signups, 2nd for current login
    };
    cleanupSignout(); // clean state before sign in
    const userPayload = await signUp(userData);
    if (userPayload?.errorCode) {
      return makeError(userPayload); // signals expected error
    } else if (userPayload) {
      // success
      const userId = userPayload.user.uid;
      setTransitionState(returnData); // to be saved into session object

      // Send message to the new users only if there is no branding data
      await Promise.allSettled([
        sendVerify &&
          sendVerificationEmail(
            userPayload.user,
            extendReturnValue(returnData)
          ),
        saveSignupData(userId, returnData[RETURN_SIGNUP_KEY]),
      ]); // swallow errors - user will have other routes to recover
      return userPayload;
    } else {
      return undefined; // failure
    }
  };

  return (
    <AuthStateContext.Provider
      value={{
        isLoading,
        isFailed,
        isReady,
        isUserLoading,
        isAuthenticated,
        authUser,
        authSession,
        authError,
        signInWithEmailAndPassword: async (email, password, optData = {}) => {
          if (isEmpty(email)) {
            throw new Error('email is required');
          }
          if (isEmpty(password)) {
            throw new Error('password is required');
          }
          cleanupSignout(); // clean state before sign in
          const userPayload = await signInWithEmail(email, password);
          if (userPayload?.errorCode) {
            return makeError(userPayload); // signals expected error
          } else if (userPayload) {
            // success
            setTransitionState(optData);
            await Promise.allSettled([pruneSessions(userPayload.user.uid)]); // swallow errors - user will have other routes to recover
            return userPayload;
          } else {
            return undefined; // failure
          }
        },
        signInWithCustomToken: async (token, optData) => {
          if (isEmpty(token)) {
            throw new Error('token is required');
          }
          cleanupSignout(); // clean state before sign in
          const userPayload = await signInWithCustomToken(token);
          if (userPayload?.errorCode) {
            return makeError(userPayload); // signals expected error
          } else if (userPayload) {
            // success
            setTransitionState(optData);
            // skip to avoid pages with long-running spinners between bounces
            // await Promise.allSettled([pruneSessions(userPayload.user.uid)]); // swallow errors - user will have other routes to recover
            return userPayload;
          } else {
            return undefined; // failure
          }
        },
        /**
         * @param {Object} userData fields + password
         * @param {Object} optData extra data (like lang)
         * @param {boolean} hasBranding branding data
         */
        signUpNewUser: async (userData, optData = {}, hasBranding = false) => {
          return await sharedSignUp(userData, optData, !hasBranding); // send verification only if no branding
        },
        /**
         * @param {Object} userData fields + password
         * @param {Object} optData extra data (like lang)
         */
        signUpInvitedUser: async (userData, optData = {}) => {
          return await sharedSignUp(userData, optData, false); // don't send verification email
        },
        signOut: doSignOut,
        fullSignOut: async () => {
          let result;
          setSigningOut(true);
          const shouldPrune = true;
          result = await stopAllSessions(authUser?.uid, shouldPrune);
          result = !!(await signOut());
          cleanupSignout();
          setSigningOut(false);
          return result; // if undefined, failed
        },
        sendForgotPasswordEmail: async (email) => {
          if (!email) {
            throw new Error('email is required');
          }
          try {
            const saved = await sendPasswordResetEmail(email, returnValue);
            if (saved?.errorCode) {
              return makeError(saved); // signals expected error
            } else if (saved) {
              return saved; // signals success
            } else {
              throw new Error('Could not send forgot password email');
            }
          } catch (err) {
            logWarning(`Could not send passord reset email: ${err.message}`, {
              err,
            });
            return; // signals failure
          }
        },
        sendVerificationEmail: async () => {
          if (!authUser) {
            throw new Error('User is required to send verification email');
          }
          try {
            const result = await sendVerificationEmail(authUser, returnValue);
            if (result?.errorCode) {
              return makeError(result); // signals expected error
            } else if (result === true) {
              return returnValue; // signals success
            } else {
              throw new Error('Failed to send verification email');
            }
          } catch (err) {
            logWarning(`Could not send verification email: ${err.message}`, {
              err,
            });
            return; // signals failure
          }
        },
        verifyActionCode: async (actionCode) => {
          if (!actionCode) {
            throw new Error('Action code is required');
          }
          try {
            const result = await verifyActionCode(actionCode);
            if (result?.errorCode) {
              return makeError(result); // signals expected error
            } else {
              return result; // returns verification/status
            }
          } catch (err) {
            logWarning(
              `Unexpected error verifying action code: ${err.message}`,
              { err }
            );
            return; // signals failure
          }
        },
        applyActionCode: async (actionCode) => {
          if (!actionCode) {
            throw new Error('Action code is required');
          }
          try {
            const result = await applyActionCode(actionCode);
            if (result?.errorCode) {
              return makeError(result); // signals expected error
            } else {
              return result; // returns verification/status
            }
          } catch (err) {
            logWarning(
              `Unexpected error applying action code: ${err.message}`,
              { err }
            );
            return; // signals failure
          }
        },
        confirmPasswordReset: async (actionCode, newPassword) => {
          if (!actionCode) {
            throw new Error('Action code is required');
          }
          if (!newPassword) {
            throw new Error('New password is required');
          }
          try {
            const result = await confirmPasswordReset(actionCode, newPassword);
            if (result?.errorCode) {
              return makeError(result); // signals expected error
            } else {
              return result; // returns confirmation status
            }
          } catch (err) {
            logWarning(
              `Unexpected error confirming password reset: ${err.message}`,
              { err }
            );
            return; // signals failure
          }
        },
        getCustomToken: async (forceRefresh) => {
          const customToken = await getCustomToken(authUser, forceRefresh);
          if (customToken) {
            return { jwt: customToken };
          }
          return; // signals failure
        },
        getIdToken: async (forceRefresh) => {
          if (authUser) {
            const idToken = await getIdToken(authUser, forceRefresh);
            if (idToken) {
              return { jwt: idToken };
            }
          }
          return; // signals failure
        },
        consumeActionReturn: consumeActionReturn,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};
/**
 * Makes the Auth context available with the useAutState hook
 * This includes the auth user, the session, and other stateful information
 * regarding the authenticated user.
 * @param {React.Component} WrappedComponent
 * @returns {React.Component}
 */
const withAuthState = (WrappedComponent) =>
  function WrappedWithAuthState(props) {
    return (
      <AuthStateProvider>
        <WrappedComponent {...props} />
      </AuthStateProvider>
    );
  };

/**
 * returns the FirebaseAuthContext object
 */
const useAuthState = () => useContext(AuthStateContext);

export { withAuthState, useAuthState };
